import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericNameDialogComponent } from './generic-name-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [GenericNameDialogComponent],
  exports: [GenericNameDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    BrowserAnimationsModule
  ]
})
export class GenericNameDialogModule { }
