import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoService } from 'src/app/services/cognito.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  isUserSignedIn: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cognitoService: CognitoService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isUserSignedIn = this.cognitoService.isUserSignedIn();

    if (this.isUserSignedIn) {
      this.router.navigateByUrl('');
    }

    this.activatedRoute.fragment.subscribe(fragment => {
      let authResponse;

      if (fragment) {
        authResponse = _.fromPairs(
          _.map(fragment.split('&'), param => {
            return param.split('=');
          })
        );
      }

      if (authResponse && authResponse.id_token) {
        this.cognitoService.setAuthToken(authResponse.id_token);
        this.router.navigateByUrl('');
      }
    });

     // This is a workaround for a bug (possibly within angular itself) that causes the fragment subscription to fire twice.
     setTimeout(() => {
      this.isUserSignedIn = this.cognitoService.isUserSignedIn();

      if (!this.isUserSignedIn) {
        this.cognitoService.redirectToSignIn();
      }
    }, 1000);
  }
}
