import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

interface DialogData {
  text: string
}

@Component({
  selector: 'app-generic-name-dialog',
  templateUrl: './generic-name-dialog.component.html',
  styleUrls: ['./generic-name-dialog.component.scss']
})
export class GenericNameDialogComponent implements OnInit {
  name: FormControl = new FormControl("");

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<GenericNameDialogComponent>
  ) { }

  ngOnInit() {
  }


  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(this.name.value);
  }
}
