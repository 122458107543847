import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ConfigService {
  constructor(private httpClient: HttpClient) {}

  // parameters loaded from config.json
  region: string;
  cognito: {
    domain: string,
    responseType: string,
    clientId: string,
    redirectUri: string,
  };
  backendUrl: string;

  ensureInit(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get("./config.json").subscribe(
        (content: ConfigService) => {
          Object.assign(this, content);
          resolve(this);
        },
        (reason) => reject(reason)
      );
    });
  }
}
