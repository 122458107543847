import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-time-error',
  templateUrl: './time-error.component.html',
  styleUrls: ['./time-error.component.scss']
})
export class TimeErrorComponent implements OnInit {
  @Input()
  time: string;

  constructor() { }

  ngOnInit() {
  }

  timeError(time: string) {
    const timePattern = /^([0-2])([0-9])(:)([0-5])([0-9])$/;
    let err;

    if (!timePattern.test(time)) {
      err = "REGEX";
    }

    return err;
  }
}
