export const languageData = [
  {
    "name": "Arabic",
    "code": "arb"
  },
  {
    "name": "Chinese, Mandarin",
    "code": "cmn-CN"
  },
  {
    "name": "Danish",
    "code": "da-DK"
  },
  {
    "name": "Dutch",
    "code": "nl-NL"
  },
  {
    "name": "English, Australian",
    "code": "en-AU"
  },
  {
    "name": "English, British",
    "code": "en-GB"
  },
  {
    "name": "English, Indian",
    "code": "en-IN"
  },
  {
    "name": "English, US",
    "code": "en-US"
  },
  {
    "name": "English, Welsh",
    "code": "en-GB-WLS"
  },
  {
    "name": "French",
    "code": "fr-FR"
  },
  {
    "name": "French, Canadian",
    "code": "fr-CA"
  },
  {
    "name": "Hindi",
    "code": "hi-IN"
  },
  {
    "name": "German",
    "code": "de-DE"
  },
  {
    "name": "Icelandic",
    "code": "is-IS"
  },
  {
    "name": "Italian",
    "code": "it-IT"
  },
  {
    "name": "Japanese",
    "code": "ja-JP"
  },
  {
    "name": "Korean",
    "code": "ko-KR"
  },
  {
    "name": "Norwegian",
    "code": "nb-NO"
  },
  {
    "name": "Polish",
    "code": "pl-PL"
  },
  {
    "name": "Portuguese, Brazilian",
    "code": "pt-BR"
  },
  {
    "name": "Portuguese, European",
    "code": "pt-PT"
  },
  {
    "name": "Romanian",
    "code": "ro-RO"
  },
  {
    "name": "Russian",
    "code": "ru-RU"
  },
  {
    "name": "Spanish, European",
    "code": "es-ES"
  },
  {
    "name": "Spanish, Mexican",
    "code": "es-MX"
  },
  {
    "name": "Spanish, US",
    "code": "es-US"
  },
  {
    "name": "Swedish",
    "code": "sv-SE"
  },
  {
    "name": "Turkish",
    "code": "tr-TR"
  },
  {
    "name": "Welsh",
    "code": "cy-GB"
  }
]