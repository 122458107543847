import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaySsmlComponent } from './play-ssml.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [PlaySsmlComponent],
  exports: [PlaySsmlComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class PlaySsmlModule { }
