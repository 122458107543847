import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClosuresComponent } from './closures.component';
import { NavigationModule } from '../navigation/navigation.module';
import { MatTabsModule, MatFormFieldModule, MatButtonModule, MatExpansionModule, MatInputModule, MatDialogModule, MatAutocompleteModule, MatSelectModule, MatCheckboxModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { GenericNameDialogModule } from '../generic-name-dialog/generic-name-dialog.module';
import { PromptsAccordionModule } from '../prompts-accordion/prompts-accordion.module';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { GenericNameDialogComponent } from '../generic-name-dialog/generic-name-dialog.component';



@NgModule({
  declarations: [ClosuresComponent],
  exports: [ClosuresComponent],
  imports: [
    CommonModule,
    NavigationModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatExpansionModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    ConfirmationDialogModule,
    GenericNameDialogModule,
    MatAutocompleteModule,
    PromptsAccordionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    GenericNameDialogComponent
  ]
})
export class ClosuresModule { }
