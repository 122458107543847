import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ContactFlowsService } from "src/app/services/contact-flows.service";
import ContactFlow, { Prompt, Attribute } from "src/app/models/contactFlow";
import { languageData } from "src/app/data/languageData";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { GenericNameDialogComponent } from "../generic-name-dialog/generic-name-dialog.component";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
  selector: "app-contact-flows",
  templateUrl: "./contact-flows.component.html",
  styleUrls: ["./contact-flows.component.scss"]
})
export class ContactFlowsComponent implements OnInit {
  groupSearch: FormControl = new FormControl("");
  flowList: string[] = [];
  activeFlow: string;
  contactFlow: ContactFlow;
  selectedLanguage: string;

  constructor(
    private contactFlowService: ContactFlowsService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.contactFlowService.getFlowList().subscribe(
      flows => {
        this.flowList = flows.sort();
      },
      err => {
        console.error("Error retrieving flow names: ", err);
      }
    );
  }

  loadFlow() {
    this.activeFlow = this.groupSearch.value;

    this.contactFlowService.getFlow(this.activeFlow).subscribe(
      contactFlow => {
        this.contactFlow = contactFlow;
      },
      err => {
        console.error("Error retrieving flow: ", err);
      }
    );
  }

  newFlow() {
    const dialogRef = this.dialog.open(GenericNameDialogComponent, {
      width: "300px",
      data: {
        text: "Contact Flow Name"
      }
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        this.groupSearch.setValue(name);
        this.activeFlow = name;
        this.contactFlow = {
          name: name,
          prompts: [],
          attributes: []
        };
      }
    });
  }

  newAttribute() {
    const dialogRef = this.dialog.open(GenericNameDialogComponent, {
      width: "300px",
      data: {
        text: "Attribute Name"
      }
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        this.contactFlowService
          .createAttribute(name, this.contactFlow.name)
          .subscribe(attr => {
            this.contactFlow.attributes.push(attr);
          });
      }
    });
  }

  newPrompt() {
    const dialogRef = this.dialog.open(GenericNameDialogComponent, {
      width: "300px",
      data: {
        text: "Prompt Name"
      }
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        this.contactFlowService
          .createPrompt(name, this.contactFlow.name)
          .subscribe(
            prompt => {
              this.contactFlow.prompts.push(prompt);
            },
            err => {
              console.error("Error creating prompt: ", err);
            }
          );
      }
    });
  }

  saveAttribute(attribute: Attribute) {
    this.contactFlowService
      .saveAttribute(attribute, this.contactFlow.name)
      .subscribe(
        updatedAttr => {
          attribute = updatedAttr;
          this.snackbarService.success("Success");
        },
        err => {
          console.error("Error saving attribute: ", err);
          this.snackbarService.error("Error saving attribute");
        }
      );
  }

  deleteAttribute(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.contactFlowService
          .deleteAttribute(id, this.contactFlow.name)
          .subscribe(
            res => {
              this.contactFlow.attributes = this.contactFlow.attributes.filter(
                attribute => attribute.id !== id
              );
              this.snackbarService.success("Success");
            },
            err => {
              console.error("Error deleting attribute: ", err);
              this.snackbarService.error("Error deleting attribute");
            }
          );
      }
    });
  }
}
