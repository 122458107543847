import { Injectable } from '@angular/core';
import HoursOfOperation from '../models/hoursOfOperation';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class HoursService {

  constructor(private httpClient: HttpClient, private config: ConfigService) { }

  getHours(): Observable<HoursOfOperation[]> {
    return this.httpClient.get<HoursOfOperation[]>(`${this.config.backendUrl}/hours`);
  }

  saveHours(hours: HoursOfOperation): Observable<HoursOfOperation> {
    return this.httpClient.put<HoursOfOperation>(
      `${this.config.backendUrl}/hours`,
      hours
    );
  }

  deleteHours(id: string): Observable<any> {
    return this.httpClient.delete<HoursOfOperation>(
      `${this.config.backendUrl}/hours/${id}`
    );
  }
  
  createHours(name: string): Observable< HoursOfOperation> {
    return this.httpClient.post<HoursOfOperation>(`${this.config.backendUrl}/hours`, {
      name: name,
      queues: ["PlaceHolder Queue"],
      timezone: "EST",
      sundayObserved: false,
      sundayStart: "00:00",
      sundayEnd: "23:59",
      mondayObserved: false,
      mondayStart: "00:00",
      mondayEnd: "23:59",
      tuesdayObserved: false,
      tuesdayStart: "00:00",
      tuesdayEnd: "23:59",
      wednesdayObserved: false,
      wednesdayStart: "00:00",
      wednesdayEnd: "23:59",
      thursdayObserved: false,
      thursdayStart: "00:00",
      thursdayEnd: "23:59",
      fridayObserved: false,
      fridayStart: "00:00",
      fridayEnd: "23:59",
      saturdayObserved: false,
      saturdayStart: "00:00",
      saturdayEnd: "23:59"
    } as HoursOfOperation);
  }
}
