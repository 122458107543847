import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { languageData } from 'src/app/data/languageData';
import PromptDialogData from 'src/app/models/promptDialog';

@Component({
  selector: 'app-edit-prompt',
  templateUrl: './edit-prompt.component.html',
  styleUrls: ['./edit-prompt.component.scss']
})
export class EditPromptComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PromptDialogData,
  ) { }

  langList = [];

  ngOnInit() {
    const activeLangs = this.data.prompt.data.map(prompt => {
      return prompt.lang;
    })
    languageData.forEach(lang => {
      if (!activeLangs.includes(lang.code)) {
        this.langList.push(lang);
      }
    })
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
