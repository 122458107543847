import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnabledDisabledPipe } from './enabled-disabled.pipe';

@NgModule({
  declarations: [EnabledDisabledPipe],
  exports: [EnabledDisabledPipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
