import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HolidayComponent } from './holiday.component';
import { NavigationModule } from '../navigation/navigation.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GenericNameDialogModule } from '../generic-name-dialog/generic-name-dialog.module';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { MatDatepickerModule, MatNativeDateModule, MatSlideToggleModule, MatAutocompleteModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { GenericNameDialogComponent } from '../generic-name-dialog/generic-name-dialog.component';
import { PromptsAccordionModule } from '../prompts-accordion/prompts-accordion.module';
import { TimeErrorModule } from '../time-error/time-error.module';

@NgModule({
  declarations: [HolidayComponent],
  imports: [
    CommonModule,
    NavigationModule,
    MatTabsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatExpansionModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    ConfirmationDialogModule,
    GenericNameDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    PromptsAccordionModule,
    MatIconModule,
    TimeErrorModule,
    MatTooltipModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    GenericNameDialogComponent
  ]
})
export class HolidayModule { }
