import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Prompt } from "src/app/models/contactFlow";
import { ContactFlowsService } from "src/app/services/contact-flows.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { languageData } from 'src/app/data/languageData';

@Component({
  selector: "app-prompts-accordion",
  templateUrl: "./prompts-accordion.component.html",
  styleUrls: ["./prompts-accordion.component.scss"]
})
export class PromptsAccordionComponent implements OnInit {
  @Input()
  prompts: Prompt[];

  @Input()
  contactFlowName: string;

  @Input()
  allowDisabledPrompts: boolean = false;

  @Output()
  updatePrompts: EventEmitter<any> = new EventEmitter();

  selectedLanguage: string;
  languageData = languageData;


  constructor(
    private contactFlowService: ContactFlowsService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.selectedLanguage = "en-US"; //Default language selection to english
  }

  doesPromptLangExist(prompt: Prompt, langCode: string): boolean {
    const langData = prompt.data.find(data => data.lang === langCode);

    if (!langData) {
      return false;
    }

    return langData.value !== "" && langData.value !== "<speak></speak>";
  }

  getPromptLangIndex(index): number {
    const i = this.prompts[index].data.findIndex(
      data => data.lang === this.selectedLanguage
    );
    if (i > -1) {
      return i;
    } else {
      const newLength = this.prompts[index].data.push({
        lang: this.selectedLanguage,
        value: "<speak></speak>"
      });
      return newLength - 1;
    }
  }

  deletePrompt(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.contactFlowService
          .deletePrompt(id, this.contactFlowName)
          .subscribe(
            res => {
              this.prompts = this.prompts.filter(
                prompt => prompt.id !== id
              );
              this.updatePrompts.emit(this.prompts);
              this.snackbarService.success("Success");
            },
            err => {
              console.error("Error deleting prompt: ", err);
              this.snackbarService.error("Error deleting prompt");
            }
          );
      }
    });
  }
}
