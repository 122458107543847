import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { CognitoService } from "../services/cognito.service";
import { SnackbarService } from '../services/snackbar.service';
import { debug } from "console";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cognitoService: CognitoService, private snackBarService: SnackbarService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(req.url === "./config.json"){
      // Skip config file
      return next.handle(req);
    }

    if (this.cognitoService.isUserSignedIn()) {
      return next
        .handle(
          req.clone({
            headers: req.headers.append(
              "Authorization",
              "Bearer " + this.cognitoService.getAuthToken()
            )
          })
        )
        .pipe(
          tap(
            event => {},
            err => {
              if (err instanceof HttpErrorResponse && (err.status == 401)) {
                console.log("Authentication Error: ", err);
                this.snackBarService.error("Session expired, redirecting you back to sign in");
                setTimeout(() => {
                  this.cognitoService.redirectToSignIn();
                }, 2500);
              }
            }
          )
        );
    } else {
      this.cognitoService.redirectToSignIn();
    }
  }
}
