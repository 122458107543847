import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { NavigationModule } from '../navigation/navigation.module';

@NgModule({
  declarations: [LandingComponent],
  exports: [LandingComponent],
  imports: [
    CommonModule,
    NavigationModule
  ]
})
export class LandingModule { }
