import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [AuthComponent],
  exports: [AuthComponent],
  imports: [CommonModule, MatProgressSpinnerModule]
})
export class AuthModule {}
