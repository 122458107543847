import { Component, OnInit } from "@angular/core";
import { HolidayService } from "src/app/services/holiday.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import Holiday from "src/app/models/holiday";
import ContactFlow, { Prompt } from 'src/app/models/contactFlow';
import { UiHelpersService } from 'src/app/services/ui-helpers.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { GenericNameDialogComponent } from '../generic-name-dialog/generic-name-dialog.component';
import { ContactFlowsService } from 'src/app/services/contact-flows.service';
import QueuePromptMapping from 'src/app/models/queuePromptMapping';
import { take } from 'rxjs/operators';

@Component({
  selector: "app-holiday",
  templateUrl: "./holiday.component.html",
  styleUrls: ["./holiday.component.scss"]
})
export class HolidayComponent implements OnInit {
  holidays: Holiday[] = [];
  timezones: string[];
  queues: string[];
  
  // Holiday Prompts are stored as a specialized "Contact Flow", this does not represent an actual flow from a Connect perspective
  holidayPromptGroup: ContactFlow;
  
  selectedTab = "HOLIDAYS";

  constructor(
    private snackBarService: SnackbarService,
    private holidayService: HolidayService,
    private uiHelpersService: UiHelpersService,
    private contactFlowService: ContactFlowsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.holidayService.getHolidays().subscribe(holidays => {
      if (holidays) {
        this.holidays = holidays;
      }
    });

    this.holidayService.getHolidayPrompts().subscribe(data => {
      this.holidayPromptGroup = data;
    });

    this.uiHelpersService.getTimezones().subscribe(tzs => {
      this.timezones = tzs;
    });

    this.uiHelpersService.getQueues().subscribe(queues => {
      this.queues = queues.sort();
    });
  }

  saveHoliday(holiday: Holiday) {
    this.holidayService.saveHoliday(holiday).subscribe(() => {
      this.snackBarService.success("Success");
    }, err => {
      this.snackBarService.error("Error saving holiday");
      console.error("Error saving holiday: ", err);
    });
  }

  deleteHoliday(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.holidayService
          .deleteHoliday(id)
          .subscribe(
            res => {
              this.holidays = this.holidays.filter(
                holiday => holiday.id !== id
              );
              this.snackBarService.success("Success");
            },
            err => {
              console.error("Error deleting holiday: ", err);
              this.snackBarService.error("Error deleting holiday");
            }
          );
      }
    });
  }
  
  addQueue(holiday: Holiday) {
    if (!this.holidayPromptGroup.prompts || this.holidayPromptGroup.prompts.length === 0) {
      this.snackBarService.error("Create at least one holiday prompt first!");
      return;
    }

    holiday.queues.push({
      name: this.queues[0],
      prompt: this.holidayPromptGroup.prompts[0].id
    });
  }

  newHoliday() {
    const dialogRef = this.dialog.open(GenericNameDialogComponent, {
      width: "300px",
      data: {
        text: "Holiday Name"
      }
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        this.holidayService.createHoliday(name).subscribe(newHoliday => {
          this.holidays.push(newHoliday);
        });
      }
    });
  }

  newPrompt() {
    const dialogRef = this.dialog.open(GenericNameDialogComponent, {
      width: "300px",
      data: {
        text: "Prompt Name"
      }
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        this.contactFlowService
          .createPrompt(name, "HolidayPrompt")
          .subscribe(
            prompt => {
              this.holidayPromptGroup.prompts.push(prompt);
            },
            err => {
              console.error("Error creating prompt: ", err);
            }
          );
      }
    });
  }

  removeQueue(holiday: Holiday, queueMapping: QueuePromptMapping) {
    holiday.queues = holiday.queues.filter(q => q !== queueMapping);
  }

  dateError(time: string) {
    const datePattern = /^([0-9])([0-9])([0-9])([0-9])([-])([0-9])([0-9])([-])([0-9])([0-9])$/;
    let err;

    if (!datePattern.test(time)) {
      err = "REGEX";
    }

    return err;
  }

  onTabChange(tab: "HOLIDAYS" | "PROMPTS") {
    if (tab === "PROMPTS") {
      console.log('updating holidays');
      const sub = this.holidayService.getHolidayPrompts()
        .pipe(take(1))
        .subscribe(data => {
        this.holidayPromptGroup = data;
      });
    }
    this.selectedTab = tab;
  }

  updatePrompts(event: Prompt[]) {
    this.holidayPromptGroup.prompts = event;
  }
}
