import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./components/app.component";
import { AuthComponent } from "./components/auth/auth.component";
import { AuthModule } from "./components/auth/auth.module";
import { LandingComponent } from "./components/landing/landing.component";
import { LandingModule } from "./components/landing/landing.module";
import { NavigationModule } from "./components/navigation/navigation.module";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { PipesModule } from './pipes/pipes.module';
import { ContactFlowsComponent } from './components/contact-flows/contact-flows.component';
import { ContactFlowsModule } from './components/contact-flows/contact-flows.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogModule } from './components/confirmation-dialog/confirmation-dialog.module';
import { GenericNameDialogModule } from './components/generic-name-dialog/generic-name-dialog.module';
import { GenericNameDialogComponent } from './components/generic-name-dialog/generic-name-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HolidayComponent } from './components/holiday/holiday.component';
import { HolidayModule } from './components/holiday/holiday.module';
import { PromptsAccordionModule } from './components/prompts-accordion/prompts-accordion.module';
import { ClosuresModule } from './components/closures/closures.module';
import { ClosuresComponent } from './components/closures/closures.component';
import { HoursModule } from './components/hours/hours.module';
import { HoursComponent } from './components/hours/hours.component';
import { TimeErrorModule } from './components/time-error/time-error.module';
import { PromptTableModule } from './components/prompt-table/prompt-table.module';
import { EditPromptComponent } from './components/edit-prompt/edit-prompt.component';
import { EditPromptModule } from './components/edit-prompt/edit-prompt.module';
import { ConfigService } from "./services/config.service";

export function ConfigServiceFactory(config: ConfigService) {
  return () => config.ensureInit();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    RouterModule.forRoot([
      {
        path: "auth",
        component: AuthComponent
      },
      {
        path: "flows",
        component: ContactFlowsComponent
      },
      {
        path: "holidays",
        component: HolidayComponent
      },
      {
        path: "closures",
        component: ClosuresComponent
      },
      {
        path: "hours",
        component: HoursComponent
      },
      {
        path: "",
        component: LandingComponent
      }
    ]),
    AuthModule,
    BrowserAnimationsModule,
    NavigationModule,
    LandingModule,
    PipesModule,
    ContactFlowsModule,
    ConfirmationDialogModule,
    GenericNameDialogModule,
    MatSnackBarModule,
    PromptsAccordionModule,
    PromptTableModule,
    HolidayModule,
    ClosuresModule,
    HoursModule,
    TimeErrorModule,
    EditPromptModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    GenericNameDialogComponent,
    EditPromptComponent
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigServiceFactory,
      deps: [ConfigService, HttpClient],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
