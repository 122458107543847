import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFlowsComponent } from './contact-flows.component';
import { NavigationModule } from '../navigation/navigation.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { GenericNameDialogModule } from '../generic-name-dialog/generic-name-dialog.module';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { GenericNameDialogComponent } from '../generic-name-dialog/generic-name-dialog.component';
import { PromptsAccordionModule } from '../prompts-accordion/prompts-accordion.module';
import { MatTooltipModule } from '@angular/material';

@NgModule({
  declarations: [ContactFlowsComponent],
  imports: [
    CommonModule,
    NavigationModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatExpansionModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    ConfirmationDialogModule,
    GenericNameDialogModule,
    MatCardModule,
    PromptsAccordionModule,
    MatTooltipModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    GenericNameDialogComponent
  ]
})
export class ContactFlowsModule { }
