import { Component, OnInit } from "@angular/core";
import { HoursService } from "src/app/services/hours.service";
import HoursOfOperation from "src/app/models/hoursOfOperation";
import QueuePromptMapping from "src/app/models/queuePromptMapping";
import { UiHelpersService } from "src/app/services/ui-helpers.service";
import { MatDialog } from '@angular/material';
import { GenericNameDialogComponent } from '../generic-name-dialog/generic-name-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: "app-hours",
  templateUrl: "./hours.component.html",
  styleUrls: ["./hours.component.scss"]
})
export class HoursComponent implements OnInit {
  hours: HoursOfOperation[] = [];
  queues: string[];
  timezones: string[];

  constructor(
    private hoursService: HoursService,
    private uiHelpersService: UiHelpersService,
    private dialog: MatDialog,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit() {
    this.hoursService.getHours().subscribe(hours => {
      if (hours) {
        this.hours = hours;
      }
    });

    this.uiHelpersService.getQueues().subscribe(queues => {
      this.queues = queues.sort();
    });

    this.uiHelpersService.getTimezones().subscribe(tzs => {
      this.timezones = tzs;
    });
  }

  newHours() {
    const dialogRef = this.dialog.open(GenericNameDialogComponent, {
      width: "300px",
      data: {
        text: "Hours Name"
      }
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        this.hoursService.createHours(name).subscribe(newHours => {
          this.hours.push(newHours);
        });
      }
    });
  }

  removeQueue(hours: HoursOfOperation, queue: string) {
    hours.queues = hours.queues.filter(q => q !== queue);
  }

  addQueue(hours: HoursOfOperation) {
    hours.queues.push(this.queues[0]);
  }

  deleteHours(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.hoursService
          .deleteHours(id)
          .subscribe(
            res => {
              this.hours = this.hours.filter(
                hours => hours.id !== id
              );
              this.snackBarService.success("Success");
            },
            err => {
              console.error("Error deleting hours: ", err);
              this.snackBarService.error("Error deleting hours");
            }
          );
      }
    });
  }

  saveHours(hours: HoursOfOperation) {
    console.log("saving hours", hours);
    this.hoursService.saveHours(hours).subscribe(() => {
      this.snackBarService.success("Success");
    }, err => {
      this.snackBarService.error("Error saving hours");
      console.error("Error saving hours: ", err);
    });
  }
}
