import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromptTableComponent } from './prompt-table.component';
import { MatFormFieldModule, MatInputModule, MatButtonModule, MatExpansionModule, MatSelectModule, MatDialogModule, MatCheckboxModule, MatSortModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { GenericNameDialogModule } from '../generic-name-dialog/generic-name-dialog.module';
import { PlaySsmlModule } from '../play-ssml/play-ssml.module';
import { MatTableModule } from '@angular/material/table';
import { EditPromptModule } from '../edit-prompt/edit-prompt.module';

@NgModule({
  declarations: [PromptTableComponent],
  exports: [PromptTableComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatExpansionModule,
    FormsModule,
    MatSelectModule,
    MatDialogModule,
    ConfirmationDialogModule,
    GenericNameDialogModule,
    MatCheckboxModule,
    PlaySsmlModule,
    MatTableModule,
    MatSortModule,
    EditPromptModule
  ]
})
export class PromptTableModule { }
