import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoursComponent } from './hours.component';
import { NavigationModule } from '../navigation/navigation.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatButtonModule, MatExpansionModule, MatInputModule, MatSelectModule, MatDialogModule, MatSlideToggleModule, MatAutocompleteModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { GenericNameDialogModule } from '../generic-name-dialog/generic-name-dialog.module';
import { PromptsAccordionModule } from '../prompts-accordion/prompts-accordion.module';
import { TimeErrorModule } from '../time-error/time-error.module';



@NgModule({
  declarations: [HoursComponent],
  exports: [HoursComponent],
  imports: [
    CommonModule,
    NavigationModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatExpansionModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    ConfirmationDialogModule,
    GenericNameDialogModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    PromptsAccordionModule,
    MatIconModule,
    TimeErrorModule,
    MatTooltipModule
  ]
})
export class HoursModule { }
