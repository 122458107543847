import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Closure from '../models/closure';
import { Observable } from 'rxjs';
import ContactFlow from '../models/contactFlow';
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class ClosuresService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  getClosures(): Observable<Closure[]> {
    return this.http.get<Closure[]>(`${this.config.backendUrl}/closures`);
  }

  // Closure Prompts are stored as a specialized "Contact Flow", this does not represent an actual flow from a Connect perspective
  getClosurePrompts(): Observable<ContactFlow> {
    return this.http.get<ContactFlow>(
      `${this.config.backendUrl}/closures/prompts`
    );
  }

  saveClosure(closure: Closure): Observable<Closure> {
    return this.http.put<Closure>(
      `${this.config.backendUrl}/closures`,
      closure
    );
  }

  deleteClosure(id: string): Observable<any> {
    return this.http.delete<Closure>(
      `${this.config.backendUrl}/closures/${id}`
    );
  }

  createClosure(name: string): Observable<Closure> {
    return this.http.post<Closure>(`${this.config.backendUrl}/closures`, {
      name: name,
      enabled: false,
      queues: []
    } as Closure);
  }
}
