/**
 * IMPORTANT:
 * Do not use this file for runtime variables. Use the following files instead:
 * frontend\src\config.json
 * frontend\src\app\services\config.service.ts
 * 
 * This allows us to "build once, redeploy many" by loading the config at runtime and not baking it into the code.
 */

export const environment = {
  env: 'production',
  production: true,
};