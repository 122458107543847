import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class PollyService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  synthesizeSsml(ssml: string): Observable<any> {
    return this.http.post(`${this.config.backendUrl}/contactFlows/getPromptAudio`, {
      ssml: ssml,
      neural: false,
      lang: 'en-US'
    });
  }
}
