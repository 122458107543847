import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import ContactFlow, { Prompt, Attribute } from '../models/contactFlow';
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class ContactFlowsService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  getFlowList(): Observable<string[]> {
    return this.http.get<string[]>(`${this.config.backendUrl}/contactFlows`);
  }

  getFlow(id: string): Observable<ContactFlow> {
    return this.http.get<ContactFlow>(`${this.config.backendUrl}/contactFlows/${id}`);
  }

  createPrompt(name: string, flowName: string): Observable<Prompt> {
    return this.http.post<Prompt>(`${this.config.backendUrl}/contactFlows/${encodeURIComponent(flowName)}/prompts`, {
      data: [],
      disabled: false,
      name: name
    } as Prompt);
  }

  savePrompt(prompt: Prompt, flowName: string): Observable<Prompt> {
    console.log("FlowName: ", flowName);
    return this.http.put<Prompt>(`${this.config.backendUrl}/contactFlows/${encodeURIComponent(flowName)}/prompts`, prompt);
  }

  deletePrompt(id: string, flowName: string): Observable<any> {
    return this.http.delete(`${this.config.backendUrl}/contactFlows/${encodeURIComponent(flowName)}/prompts/${id}`);
  }

  createAttribute(name: string, flowName: string): Observable<Attribute> {
    return this.http.post<Attribute>(`${this.config.backendUrl}/contactFlows/${encodeURIComponent(flowName)}/attributes`, {
      name: name,
      data: name
    } as Attribute);
  }

  saveAttribute(attribute: Attribute, flowName: string): Observable<Attribute> {
    return this.http.put<Attribute>(`${this.config.backendUrl}/contactFlows/${encodeURIComponent(flowName)}/attributes`, attribute);
  }

  deleteAttribute(id: string, flowName: string): Observable<any> {
    return this.http.delete(`${this.config.backendUrl}/contactFlows/${encodeURIComponent(flowName)}/attributes/${id}`);
  }
}
