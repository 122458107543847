import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enabledDisabled'
})
export class EnabledDisabledPipe implements PipeTransform {

  transform(value: boolean, args?: any): any {
    return value ? 'enabled' : 'disabled';
  }

}
