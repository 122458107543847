import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditPromptComponent } from './edit-prompt.component';
import { MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatTooltipModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { PlaySsmlModule } from '../play-ssml/play-ssml.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [EditPromptComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    PlaySsmlModule,
    MatSelectModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTooltipModule
  ]
})
export class EditPromptModule { }
