import { Component, OnInit, Input } from '@angular/core';
import { CognitoService } from 'src/app/services/cognito.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input()
  big: boolean;
  
  constructor(public cognitoService: CognitoService) { }

  ngOnInit() {
    this.signInCheck();
  }

  signInCheck() {
    if (!this.cognitoService.isUserSignedIn()) {
      this.cognitoService.redirectToSignIn();
    }
  }

}
