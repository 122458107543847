import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root"
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  success(msg: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = "main__snackbar";
    config.duration = 2000;
    this.snackBar.open(msg, null, config);
  }

  error(msg: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = "main__snackbar--err";
    config.duration = 2000;
    this.snackBar.open(msg, null, config);
  }
}
