import { Component, OnInit } from '@angular/core';
import ContactFlow, { Prompt } from 'src/app/models/contactFlow';
import Closure from 'src/app/models/closure';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ClosuresService } from 'src/app/services/closures.service';
import { UiHelpersService } from 'src/app/services/ui-helpers.service';
import { ContactFlowsService } from 'src/app/services/contact-flows.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { GenericNameDialogComponent } from '../generic-name-dialog/generic-name-dialog.component';
import QueuePromptMapping from 'src/app/models/queuePromptMapping';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-closures',
  templateUrl: './closures.component.html',
  styleUrls: ['./closures.component.scss']
})
export class ClosuresComponent implements OnInit {
  closures: Closure[] = [];
  queues: string[];
  
  // Closure Prompts are stored as a specialized "Contact Flow", this does not represent an actual flow from a Connect perspective
  closurePromptGroup: ContactFlow;
  
  selectedTab = "CLOSURES";

  constructor(
    private snackBarService: SnackbarService,
    private closuresService: ClosuresService,
    private uiHelpersService: UiHelpersService,
    private contactFlowService: ContactFlowsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.closuresService.getClosures().subscribe(closures => {
      if (closures) {
        this.closures = closures;
      }
    });

    this.closuresService.getClosurePrompts().subscribe(data => {
      this.closurePromptGroup = data;
    });

    this.uiHelpersService.getQueues().subscribe(queues => {
      this.queues = queues.sort();
    });
  }

  saveClosure(closure: Closure) {
    this.closuresService.saveClosure(closure).subscribe(() => {
      this.snackBarService.success("Success");
    }, err => {
      this.snackBarService.error("Error saving closure");
      console.error("Error saving closure: ", err);
    });
  }

  deleteClosure(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.closuresService
          .deleteClosure(id)
          .subscribe(
            res => {
              this.closures = this.closures.filter(
                closure => closure.id !== id
              );
              this.snackBarService.success("Success");
            },
            err => {
              console.error("Error deleting closure: ", err);
              this.snackBarService.error("Error deleting closure");
            }
          );
      }
    });
  }
  
  addQueue(closure: Closure) {
    if (!this.closurePromptGroup.prompts || this.closurePromptGroup.prompts.length === 0) {
      this.snackBarService.error("Create at least one holiday prompt first!");
      return;
    }
    closure.queues.push({
      name: this.queues[0],
      prompt: this.closurePromptGroup.prompts[0].id
    });
  }

  newClosure() {
    const dialogRef = this.dialog.open(GenericNameDialogComponent, {
      width: "300px",
      data: {
        text: "Closure Name"
      }
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        this.closuresService.createClosure(name).subscribe(newClosure => {
          this.closures.push(newClosure);
        });
      }
    });
  }

  newPrompt() {
    const dialogRef = this.dialog.open(GenericNameDialogComponent, {
      width: "300px",
      data: {
        text: "Prompt Name"
      }
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        this.contactFlowService
          .createPrompt(name, "ClosurePrompt")
          .subscribe(
            prompt => {
              this.closurePromptGroup.prompts.push(prompt);
            },
            err => {
              console.error("Error creating prompt: ", err);
            }
          );
      }
    });
  }

  removeQueue(closure: Closure, queueMapping: QueuePromptMapping) {
    closure.queues = closure.queues.filter(q => q !== queueMapping);
  }

  updatePrompts(event: Prompt[]) {
    this.closurePromptGroup.prompts = event;
  }


  onTabChange(tab: "CLOSURES" | "PROMPTS") {
    if (tab === "PROMPTS") {
      console.log('updating holidays');
      this.closuresService.getClosurePrompts()
        .pipe(take(1))
        .subscribe(data => {
        this.closurePromptGroup = data;
      });
    }
    this.selectedTab = tab;
  }
}
