import { Component, OnInit, Input } from "@angular/core";
import { PollyService } from "src/app/services/polly.service";

@Component({
  selector: "app-play-ssml",
  templateUrl: "./play-ssml.component.html",
  styleUrls: ["./play-ssml.component.scss"]
})
export class PlaySsmlComponent implements OnInit {
  @Input()
  ssml: string;

  @Input()
  label: string;

  constructor(private pollyService: PollyService) {}

  ngOnInit() {}

  play() {
    this.pollyService.synthesizeSsml(this.ssml).subscribe(res => {
      let context = new AudioContext();
      let mp3Buffer: any = new ArrayBuffer(res.AudioStream.length);

      var bufferView = new Uint8Array(mp3Buffer);
      for (let i = 0; i < res.AudioStream.length; i++) {
        bufferView[i] = res.AudioStream[i];
      }

      context.decodeAudioData(
        mp3Buffer,
        buffer => {
          var source = context.createBufferSource();
          source.buffer = buffer;
          source.connect(context.destination);
          source.start(0);
        },
        err => {
          console.log("failed to play audio");
        }
      );
    });
  }
}
